import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import { useEffect } from 'react';
import { loginSuccess } from 'src/store/auth/authSlice';
import { useAppDispatch } from './hooks/store';
import ThemeProvider from './theme/ThemeProvider';

import { LicenseInfo } from '@mui/x-license-pro';
import { SnackbarProvider } from './contexts/SnackbarContext';

function App() {
  const content = useRoutes(router);
  const dispatch = useAppDispatch();
  const LICENSE = process.env.REACT_APP_MUI_LICENSE_KEY;
  LicenseInfo.setLicenseKey(LICENSE);

  useEffect(() => {
    const storedUserData = localStorage.getItem('___reduxState__auth');
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      if (userData) dispatch(loginSuccess(userData));
    }
  }, [dispatch]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider>
          <CssBaseline />
          {content}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
