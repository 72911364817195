import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from './baseQuery';

export interface Route {
	id: number;
	name: string;
	shippingCompanyId: number;
	stops: RouteStop[];
}

export interface RouteStop {
	center_id: number;
	center_name: string;
	estimated_packages: string;
	shipment_id: number;
}

export const apiRoute = createApi({
	reducerPath: 'api_route',
	baseQuery: baseQueryWithReAuth,
	endpoints: builder => ({
		createPresetRoute: builder.mutation<any, any>({
			query: body => ({
				url: `api/routes/create-preset-route`,
				method: 'POST',
				body,
			}),
		}),
		createRoute: builder.mutation<any, any>({
			query: body => ({
				url: `api/routes/create-route`,
				method: 'POST',
				body,
			}),
		}),
		updateRoute: builder.mutation<any, any>({
			query: body => ({
				url: `api/routes/update-route`,
				method: 'PUT',
				body,
			}),
		}),
		updateRouteStopsDetail: builder.mutation<any, any>({
			query: body => ({
				url: `api/routes/update-route-stops-detail`,
				method: 'PUT',
				body,
			}),
		}),
		getAllPresetRoutes: builder.mutation<any, void>({
			query: () => ({
				url: `api/routes/get-all-preset-routes`,
				method: 'GET',
			}),
		}),
		getAllRoutes: builder.mutation<any, void>({
			query: () => ({
				url: `api/routes/get-all-routes`,
				method: 'GET',
			}),
		}),
		getRouteById: builder.mutation<any, any>({
			query: id => ({
				url: `api/routes/route/${id}`,
				method: 'GET',
			}),
		}),
		getPresetRouteById: builder.mutation<any, any>({
			query: id => ({
				url: `api/routes/preset-route/${id}`,
				method: 'GET',
			}),
		}),
		searchPickups: builder.mutation<any, any>({
			query: body => ({
				url: `api/routes/searchPickups`,
				method: 'POST',
				body,
			}),
		}),
		searchStops: builder.mutation<any, any>({
			query: body => ({
				url: `api/routes/searchStops`,
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const {
	useCreatePresetRouteMutation,
	useGetAllPresetRoutesMutation,
	useCreateRouteMutation,
	useGetAllRoutesMutation,
	useGetRouteByIdMutation,
	useUpdateRouteMutation,
	useGetPresetRouteByIdMutation,
	useUpdateRouteStopsDetailMutation,
	useSearchPickupsMutation,
	useSearchStopsMutation,
} = apiRoute;
