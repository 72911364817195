import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { IContainer } from 'src/models/container';

export interface GetContainerByIdRequest {
  container_id: number;
}

export interface DeleteIContRequest {
  container_id: number;
}

export interface UpdateWeightRequest {
  container_id: number;
  weight: number;
}

export interface IGetContainerBySomeIdRequest {
  id: number[];
}

export interface GetContainerCountByProductTypeIdRequest {
  product_type_id: number;
}

export const apiContainers = createApi({
  // reducerPath: 'api_containers',
  baseQuery: baseQueryWithReAuth,
  reducerPath: 'containers',
  endpoints: (builder) => ({
    searchContainers: builder.mutation<any, any>({
      query: (body) => ({
        url: `api/containers/search`,
        method: 'POST',
        body,
      }),
    }),
    getContainerByID: builder.mutation<any, GetContainerByIdRequest>({
      query: (GetContainerByIdRequest) => ({
        url: `api/containers/get-container-by-id?container_id=${GetContainerByIdRequest.container_id}`,
        method: 'GET',
      }),
    }),
    getAllContainersInfo: builder.mutation<any, void>({
      query: () => ({
        url: 'api/containers/getAll',
        method: 'GET',
      }),
    }),
    getAllContainersWithProduct: builder.mutation<any, void>({
      query: () => ({
        url: 'api/containers/getAllContainersWithProduct',
        method: 'GET',
      }),
    }),
    getVoidContainers: builder.mutation<IContainer[], void>({
      query: () => ({
        url: 'api/containers/get-void-containers',
        method: 'GET',
      }),
    }),
    getCuttableContainers: builder.mutation<IContainer[], void>({
      query: () => ({
        url: 'api/containers/get-cuttable-containers',
        method: 'GET',
      }),
    }),
    getAllContainersByType: builder.mutation<any, any>({
      query: (body) => ({
        url: 'api/containers/get-all-containers-by-type',
        method: 'POST',
        body
      }),
    }),
    updateContainerWeight: builder.mutation<any, UpdateWeightRequest>({
      query: (body) => ({
        url: 'api/containers/update-container-weight',
        method: 'PUT',
        body,
      }),
    }),
    updateContainer: builder.mutation<any, any>({
      query: (data) => ({
        url: `api/containers/edit?container_id=${data.container_id}`,
        method: 'PUT',
        body: data.data,
      }),
    }),
    createContainer: builder.mutation<any, any>({
      query: (body) => ({
        url: 'api/containers/create',
        method: 'POST',
        body,
      }),
    }),
    deleteIContainer: builder.mutation<any, DeleteIContRequest>({
      query: (DeleteIContRequest) => ({
        url: `api/containers/deleteICont?ICont_id=${DeleteIContRequest.container_id}`,
        method: 'DELETE',
      }),
    }),
    getContainerTypes: builder.mutation<any, void>({
      query: () => ({
        url: 'api/containers/getAllContainerTypes',
        method: 'GET'
      })
    }),
    getContainerCountByProductTypeId: builder.mutation<any, GetContainerCountByProductTypeIdRequest>({
      query: (GetContainerCountByProductTypeIdRequest) => ({
        url: `api/containers/getContainerCountByProductTypeId?product_type_id=${GetContainerCountByProductTypeIdRequest.product_type_id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetContainerByIDMutation,
  useGetAllContainersInfoMutation,
  useGetVoidContainersMutation,
  useUpdateContainerWeightMutation,
  useUpdateContainerMutation,
  useCreateContainerMutation,
  useDeleteIContainerMutation,
  useGetCuttableContainersMutation,
  useGetContainerTypesMutation,
  useGetAllContainersWithProductMutation,
  useSearchContainersMutation,
  useGetContainerCountByProductTypeIdMutation,
  useGetAllContainersByTypeMutation
} = apiContainers;
