import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from './baseQuery';

export interface ReceptionListRequest {
	status_id?: number;
}

export const receptionsApi = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath: 'receptions',
	endpoints: builder => ({
		getAllReceptions: builder.mutation<any, ReceptionListRequest>({
			query: request => ({
				url: `api/receptions/getAll${request && request?.status_id ? `?status_id=${request.status_id}` : ''}`,
				method: 'GET',
			}),
		}),
		getReceptionById: builder.mutation<any, any>({
			query: id => ({
				url: `api/receptions/${id}`,
				method: 'GET',
			}),
		}),
		performReceptionLine: builder.mutation<any, any>({
			query: body => ({
				url: `api/receptions/performReceptionLine`,
				method: 'POST',
				body,
			}),
		}),
		performReceptionShipment: builder.mutation<any, any>({
			query: body => ({
				url: `api/shipments/perform-reception`,
				method: 'POST',
				body,
			}),
		}),
	}),
});

export const {
	useGetAllReceptionsMutation,
	useGetReceptionByIdMutation,
	usePerformReceptionLineMutation,
	usePerformReceptionShipmentMutation,
} = receptionsApi;
